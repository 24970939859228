import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useWindowWidth } from "@react-hook/window-size"

/* Import Global Assets */
import IndexIconSvg from "~assets/images/new-icons/icon-index.svg"
import TraceIconSvg from "~assets/images/new-icons/icon-trace.svg"
import TemporaryDwellingsIconSvg from "~assets/images/new-icons/icon-temporary-dwellings.svg"
import AboutIconSvg from "~assets/images/new-icons/icon-about.svg"
import IndexIconMobileSvg from "~assets/images/new-icons/mobile/icon-index.svg"
import TraceIconMobileSvg from "~assets/images/new-icons/mobile/icon-trace.svg"
import TemporaryDwellingsIconMobileSvg from "~assets/images/new-icons/mobile/icon-temporary-dwellings.svg"
import AboutIconMobileSvg from "~assets/images/new-icons/mobile/icon-about.svg"
import UpcomingIconSvg from "~assets/images/new-icons/icon-upcoming.svg"
import UpcomingIconMobileSvg from "~assets/images/new-icons/mobile/icon-upcoming.svg"

/* Import Local Style(s) */
import "./header.css"

const Header = () => {
  const {
    allSanityProject: {
      totalCount
    }
  } = useStaticQuery(graphql`
    query UpcomingProjectsCountQuery {
      allSanityProject(filter: {upcomingProject: {eq: true}}) {
        totalCount
      }
    }
  `)

  const windowWidth = useWindowWidth()

  return (
    <div className="temporary-dwellings-header">
      <a className="logo" href="https://theportal.place">
        <p>The Portal</p>
      </a>
      <div className="icons">
        <a href="https://theportal.place/projects">
          <img
            className="icon"
            src={windowWidth > 768 ? IndexIconSvg : IndexIconMobileSvg}
            alt="Index"
          />
        </a>
        <a href="https://theportal.place/trace">
          <img
            className="icon"
            src={windowWidth > 768 ? TraceIconSvg : TraceIconMobileSvg}
            alt="Trace"
          />
        </a>
        <Link to="/">
          <img
            className="icon"
            src={
              windowWidth > 768
                ? TemporaryDwellingsIconSvg
                : TemporaryDwellingsIconMobileSvg
            }
            alt="Temporary Dwellings"
          />
        </Link>
        <a href="https://theportal.place/about">
          <img
            className="icon"
            src={windowWidth > 768 ? AboutIconSvg : AboutIconMobileSvg}
            alt="About"
          />
        </a>
        {totalCount > 0 && 
          <a href="https://theportal.place/upcoming-projects">
            <div className="icons desktop-only">
              <Link to="/upcoming-projects">
                <div className="icon-wrapper">
                  <img
                    className="icon rotate"
                    src={windowWidth > 768 ? UpcomingIconSvg : UpcomingIconMobileSvg}
                    alt=""
                  />
                </div>
              </Link>
            </div>
          </a>
        }        
      </div>
    </div>
  )
}
export default Header
